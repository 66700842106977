import React, { useEffect, useState } from 'react';
import { getMenuBySlug } from "../api/wordpress";
import logo from '../images/easy-agent.png';
import americaCanadianClients from '../images/america-canadian-clients.png';
import { Link } from 'react-router-dom';
const footerConfig = {
    menuSlugs: {
        MAIN_MENU: 'main-menu',
        RESOURCE_MENU: 'resources',
        EASY_AGENT_PRO_MENU: 'easy-agent-pro-menu',
    },
    contactInfo: {
        time: "Monday-Friday 9-5 MST",
        phone: "(800) 327-6623",
        address: "15056 Antioch, Overland Park, KS 66221",
    }
};


function Footer() {
    const [menuItems, setMenuItems] = useState({});

    //   useEffect(() => {
    //       const fetchMenus = async () => {
    //           try {
    //               const data = await getMenus();
    //               setMenus(data);
    //           } catch (err) {
    //               console.error(err.message);
    //           }
    //       };
    //       fetchMenus();
    //   }, []); 

    //   useEffect(() => {
    //       const fetchSettings = async () => {
    //           try {
    //               const data = await getSettings();
    //               setSettings(data);
    //           } catch (err) {
    //               console.error(err.message);
    //           }
    //       };
    //       fetchSettings();
    //   }, []);

    useEffect(() => {
        const fetchMenuItemsBySlug = async (slug) => {
            try {
                const data = await getMenuBySlug(slug);
                setMenuItems((prevState) => ({
                    ...prevState,
                    [slug]: data.items || [],
                }));
            } catch (err) {
                console.error(`Error fetching menu by slug "${slug}": ${err.message}`);
            }
        };
        fetchMenuItemsBySlug(footerConfig.menuSlugs.MAIN_MENU); // Fetch menu items for the "main-menu" from wp-admin
        fetchMenuItemsBySlug(footerConfig.menuSlugs.RESOURCE_MENU); // Fetch menu items for the "resourses" from wp-admin
        fetchMenuItemsBySlug(footerConfig.menuSlugs.EASY_AGENT_PRO_MENU); // Fetch menu items for the "easy-agent-pro-menu" from wp-admin
    }, []); // Run only once on mount

    const renderMenuItems = (slug) => {
        const items = menuItems[slug] || [];
        return (
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <Link
                            to={item.url}
                            dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                        ></Link>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <footer className="site-footer">
        <div className="pre-footer">
            <div className="container-mid">
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="footer-menu-container">
                            <div className="footer-menu-title">Support</div>
                            <div className="footer-menu-contact">
                                <div className="footer-menu-contact-title">Time</div>
                                <p>{footerConfig.contactInfo.time}</p>
                            </div>
                            <div className="footer-menu-contact">
                                <div className="footer-menu-contact-title">Phone</div>
                                <Link to="#">{footerConfig.contactInfo.phone}</Link>
                            </div>
                            <div className="footer-menu-contact">
                                <div className="footer-menu-contact-title">Address</div>
                                <Link to="#">{footerConfig.contactInfo.address}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="footer-menu-container">
                            <div className="footer-menu-title">LeadSites</div>
                            {renderMenuItems(footerConfig.menuSlugs.MAIN_MENU)}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="footer-menu-container">
                            <div className="footer-menu-title">Resources</div>
                            {renderMenuItems(footerConfig.menuSlugs.RESOURCE_MENU)}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="footer-menu-container">
                            <div className="footer-menu-title">Easy Agent Pro</div>
                            {renderMenuItems(footerConfig.menuSlugs.EASY_AGENT_PRO_MENU)}
                            <div className="footer-country-logo">
                                <img
                                    src={americaCanadianClients}
                                    className="img-fluid"
                                    alt="usa-flag"
                                    title="usa-flag"
                                    width="250"
                                    height="100"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="copyright-text">
            <div className="container-mid">
                <div className="copyright-text-container">
                    <div className="footer-logo">
                        <img src={logo} className="img-fluid" alt="Easy Agent Pro" title="Easy Agent Pro" width="250" height="100" />
                    </div>
                    <div className="copyright-text-content">
                        <p>
                            Copyright 2025 <Link to="/"> Easy Agent PRO</Link>  |  
                            <Link to="/sitemap"> Sitemap</Link> 
                            {/* <Link to="#">Terms of Use</Link> |  
                            <Link to="#">Privacy Policy</Link> |  
                            <Link to="#">DMCA</Link> */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer;
