import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import homeBannerBgImg from '../images/home-banner.webp'
import leadGenMadeEasy from '../images/lead-generation-made-easy.webp'
import builtForAgents from '../images/built-for-agents.webp'
import noContractsRequired from '../images/no-contracts-required.webp'
import intergration from '../images/integration.webp'
import ourClient from '../images/our-client.png'
import growYourBusiness from '../images/grow-your-business.webp'
import dustinBrohm from '../images/dustin-brohm.webp'
import darrenOConnor from '../images/darren-o-connor.webp'
import danielKeeton from '../images/daniel-keeton.webp'
import karinCarr from '../images/karin-carr.webp'
import AOS from "aos";
import BlogList from "../components/blogGrid";
import Seo from "../components/Seo";

// Configuration file for managing content
const config = {
    metaDetails: {
        title: "Custom Real Estate Websites & Marketing Solutions | Easy Agent PRO",
        description: "Empower your real estate business with custom websites that put you in control of your brand and marketing. Generate leads and build your success with Easy Agent PRO",
        canonical: "https://www.easyagentpro.com/",
    },
    homeBanner: {
        backgroundImage: `url('${homeBannerBgImg}')`,
        title: "Your All-in-One Platform for Real Estate Success",
        description:
            "Empower your growth with lead-generating tools and a dedicated partner to help you grow your business.",
        buttonText: "Learn More About Lead Sites",
        buttonLink: "/leadsite",
    },
    whyChooseUs: {
        sectionTitle: "Why Agents, Teams, & Brokers Choose LeadSites by Easy Agent PRO",
        subHeading: "[Trusted Solutions]",
        features: [
            {
                icon: leadGenMadeEasy,
                title: "Lead Generation Made Easy",
                aos: "fade-up",
                aosDelay: "0",
                aosDuration: "900",
                description:
                    "Generate high-quality leads with beautiful, IDX-ready websites and 25 easy-to-use apps that turn visitors into clients.",
            },
            {
                icon: builtForAgents,
                title: "LeadSites Are Built For Agents",
                aos: "fade-up",
                aosDelay: "0",
                aosDuration: "900",
                description:
                    "Designed specifically for real estate professionals, LeadSites are easy to use, visually stunning, and packed with features that help you focus on closing deals.",
            },
            {
                icon: noContractsRequired,
                title: "Unmatched Value - No Contracts Required",
                aos: "fade-up",
                aosDelay: "0",
                aosDuration: "900",
                description:
                    "Get the best value on the market with a powerful, affordable solution that supports your business growth—no contracts required.",
            },
            {
                icon: intergration,
                title: "Seamless Integrations",
                aos: "fade-up",
                aosDelay: "0",
                aosDuration: "900",
                description:
                    "Easily connect LeadSites with popular CRMs, marketing tools, and social platforms to streamline your workflow to reach more clients easily.",
            },
        ],
    },
    homeClient: {
        subHeading: "[Connections]",
        title: "Over 3,000 websites built with direct connections to 65 Local MLS Boards.",
        image: ourClient,
    },
    whoWeAre: {
        subHeading: "[Who we are]",
        title: "A team dedicated to your success",
        description:
            "Leadsites is the best website marketing platform for real estate agents who want to get leads online. We focus on simple and easy ways to get leads.",
        buttonText: "About Us",
        buttonLink: "#",
        image: "images/who-we-care-img.webp",
        features: [
            {
                icon: "images/support.webp",
                title: "Support You Can Depend On",
                description:
                    "With over 300,000 support tickets and a 96% 5-star approval rating, our team is here to help you every step of the way. Real estate marketing can be challenging, but you don’t have to go it alone—our support team is on your side.",
            },
            {
                icon: "images/experienced-marketing.webp",
                title: "Experienced Marketing Pros",
                description:
                    "Our team brings hands-on expertise across Google Ads, Social Media Marketing, YouTube, Recruiting, and more. We understand the strategies that drive real estate success and make it easy for you to connect with clients.",
            },
            {
                icon: "images/product-developers.webp",
                title: "Product Developers With Vision",
                description:
                    "Our development team specializes in integration, customization, and creating unique applications tailored for real estate agents. We innovate constantly to ensure LeadSites have everything you need to succeed in a fast-paced market.",
            },
        ],
    },
    leadsVideo: {
        sectionClass: "leadsVideo",
        subHeading: "[Leadsites]",
        title: "See LeadSites In Action",
        videoUrl: "https://www.youtube.com/embed/S_A9CwANKcU?si=498hJz8zIZcBrKo-",
        buttonText: "Contact Us",
        buttonLink: "https://www.easyagentpro.com/contact/",
    },
    leadSiteTools: {
        sectionClass: "leadSite_tools",
        subHeading: "[Grow Your Business:]",
        title: "LeadSites comes with a library of tools to help you grow your business",
        tools: [
            {
                title: "Customizable Landing Pages",
                description:
                    "Create stunning landing pages for a variety of strategies, including single property, cash offer, open house, and more.",
            },
            {
                title: "Home Page Builder App",
                description:
                    "Create, update, or refresh! Our Home Page Builder app offers hundreds of layout variations for a homepage that fits your brand.",
            },
            {
                title: "Built-In SEO and Content Tools",
                description:
                    "Improve your online presence with SEO-optimized content templates that attract more visitors.",
            },
        ],
        image: growYourBusiness,
        additionalTools: [
            {
                title: "Instafarm",
                description:
                    "Showcase your expertise and capture leads! With our Instafarm app, easily create pages for any neighborhood, community, or niche.",
            },
            {
                title: "API and Integration Capabilities",
                description:
                    "LeadSites seamlessly connects with your existing tools via API integration, making it easy to enhance and scale your workflow.",
            },
            {
                title: "New! Easy SEO",
                description:
                    "AI-driven SEO companion that helps you optimize your site by prioritizing tasks based on your strategy and availability.",
            },
        ],
        ctaButtons: [
            { text: "Schedule A Full Demo", link: "#" },
            { text: "Try It Free Today", link: "#" },
        ],
    },
    expertReviews: {
        sectionClass: "expertReviews",
        heading: "Trusted by Real Estate Professionals Nationwide",
        testimonials: [
            {
                quote: "If it wasn't for the ease of use that my Leadsite gave me, I never would have been able to make content marketing work.",
                name: "Dustin Brohm",
                title: "Agent & Lead Gen Coach",
                image: dustinBrohm,
            },
            {
                quote: "Two seven figure deals in the same month from our Leadsite. EAP is my secret weapon.",
                name: "Darren O'Connor",
                title: "Real Estate Agent",
                image: darrenOConnor,
            },
            {
                quote: "We built a niche website with EAP in a new market and within weeks the site was ranking on page 1. We started to get leads shortly after and closed our first deal within 4 months of the launch.",
                name: "Daniel Keeton",
                title: "Virginia Broker",
                image: danielKeeton,
            },
            {
                quote: "Easy Agent PRO is hands down the thing that propelled my real estate career to an entirely new level.",
                name: "Karin Carr",
                title: "Real Estate Agent & Coach",
                image: karinCarr,
            },
        ],
    },

    homeBlog: {
        sectionClass: "homeBlog",
        heading: "Expert Advice to Fuel Your Success",
        description: "Stay ahead with expert advice from Easy Agent Pro. Our blog is packed with insights on real estate marketing, lead generation, and success strategies tailored for agents, teams, and brokers.",
        articles: ['7-free-real-estate-leads', 'maximizing-social-media-presence-for-real-estate-success', 'real-estate-blog-seo-2'],
    },

    growSiteCTA: {
        sectionClass: "growSite_cta",
        heading: "Ready To Grow Your Real Estate Business With LeadSites?",
        description: "Experience the tools that thousands of real estate agents trust to grow their businesses.",
        ctaButtons: [
            {
                text: "Schedule Your Free Demo Today",
                link: "https://www.easyagentpro.com/leadsites-appointment/",
                className: "theme-btn white-btn mt-3",
            },
            {
                text: "Learn More About LeadSites",
                link: "/eap-pricing",
                className: "theme-btn white-btn ms-2 mt-3",
            },
        ],
    },
};

const Home = () => {
    useEffect(() => {
        AOS.refresh();
    });

    // Custom Previous Arrow
    const PrevArrow = ({ onClick }) => {
        // return <div className="custom-prev-arrow" onClick={onClick}>❮</div>;
        return <button className="carousel-control-prev" type="button" onClick={onClick}> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
    };

    // Custom Next Arrow
    const NextArrow = ({ onClick }) => {
        return <button className="carousel-control-next" type="button" onClick={onClick} > <span className="carousel-control-next-icon" aria-hidden="true" ></span> <span className="visually-hidden">Next</span> </button>
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default number of slides
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1024, // For tablets and smaller screens
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768, // For mobile devices
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            
            <Seo
                title={config.metaDetails.title}
                description={config.metaDetails.description}
                url={window.location.href}
            />
            <section
                className="home-banner"
                style={{ backgroundImage: config.homeBanner.backgroundImage }}
            >
                <div className="container-mid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-12 text-center" data-aos="fade-up" data-aos-duration="900">
                            <div className="home-banner-content wow fadeInUp">
                                <h1 className="mb-3">{config.homeBanner.title}</h1>
                                <p>{config.homeBanner.description}</p>
                                <Link to={config.homeBanner.buttonLink} className="theme-btn mt-3">
                                    <span>{config.homeBanner.buttonText}</span>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-12 mt-5" data-aos="fade-up" data-aos-duration="1000">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>

            <section className="whyChoose_us">
                <div className="container-mid">
                    <div className="row">
                        <div className="col-md-12 main-heading mb-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
                            <div className="section-title text-center wow fadeInUp">
                                <div className="sub-head">{config.whyChooseUs.subHeading}</div>
                                <h2>{config.whyChooseUs.sectionTitle}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {config.whyChooseUs.features.map((feature, index) => (
                            <div className="col-md-6 wow fadeInUp" key={index} data-aos={feature.aos} data-aos-duration={feature.aosDuration} data-aos-delay={feature.aosDelay}>
                                <div className="whyChoose_us_grid">
                                    <div className="whyChoose_us_grid_title">
                                        <div className="whyChoose_us_grid_icon">
                                            <img
                                                src={feature.icon}
                                                className="img-fluid"
                                                alt={feature.title}
                                                title={feature.title}
                                                width="40"
                                                height="40"
                                            />
                                        </div>
                                        <h3>{feature.title}</h3>
                                    </div>
                                    <div className="whyChoose_us_grid_content">
                                        <p>{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="homeClient">
                <div className="container-mid">
                    <div className="main-heading heading-light text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900">
                        <div className="sub-head">{config.homeClient.subHeading}</div>
                        <h2>{config.homeClient.title}</h2>
                        <img
                            src={config.homeClient.image}
                            className="img-fluid mt-3"
                            alt="MLS Boards"
                            title="MLS Boards"
                            width="1000"
                            height="500"
                        />
                    </div>
                </div>
            </section>
            {/* Leads Video Section */}
            <section className={config.leadsVideo.sectionClass}>
                <div className="container-mid">
                    <div className="row align-items-center">
                        <div className="col-md-7 wow fadeInLeft" data-aos="fade-right" data-aos-duration="900">
                            <div className="leadsVideo_in">
                                <iframe
                                    width="100%"
                                    height="400"
                                    src={config.leadsVideo.videoUrl}
                                    title="Lead Video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        <div className="col-md-5 ps-md-5 wow fadeInRight" data-aos="fade-left" data-aos-duration="900">
                            <div className="main-heading heading-light">
                                <div className="sub-head">{config.leadsVideo.subHeading}</div>
                                <h2>{config.leadsVideo.title}</h2>
                                <Link to={config.leadsVideo.buttonLink} className="theme-btn">
                                    <span>{config.leadsVideo.buttonText}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* LeadSite Tools Section */}
            <section className={config.leadSiteTools.sectionClass}>
                <div className="container-mid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading mb-5 text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900">
                                <div className="sub-head">{config.leadSiteTools.subHeading}</div>
                                <h2>{config.leadSiteTools.title}</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInLeft" data-aos="fade-right" data-aos-duration="1300">
                            {config.leadSiteTools.tools.map((tool, index) => (
                                <div className={`leadSite_tools_grid ww_grid${index + 1}`} key={index}>
                                    <h3>{tool.title}</h3>
                                    <p>{tool.description}</p>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-6 wow fadeInRight" data-aos="fade-left" data-aos-duration="900">
                            <div className="leadSite_tools_img">
                                <img
                                    src={config.leadSiteTools.image}
                                    className="img-fluid mb-3"
                                    alt="Grow Your Business"
                                    title="Grow Your Business"
                                    width="767"
                                    height="600"
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="row">
                                {config.leadSiteTools.additionalTools.map((tool, index) => (
                                    <div className="col-md-4" data-aos="fade-up" data-aos-duration="900" key={index}>
                                        <div className={`leadSite_tools_grid ww_grid${index + 1} wow fadeInUp`}>
                                            <h3>{tool.title}</h3>
                                            <p>{tool.description}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-md-12 text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                                    <div className="leadSite_tools_cta">
                                        {config.leadSiteTools.ctaButtons.map((button, index) => (
                                            <Link to={button.link} className={`theme-btn ${index === 1 ? "white-btn" : ""} mt-4`} key={index}>
                                                <span>{button.text}</span>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Expert Reviews Section */}
            <section className={config.expertReviews.sectionClass}>
                <div className="container-mid">
                    <div className="row">
                        <div className="col-md-12 main-heading mb-4 text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div className="sub-head">[Testimonials]</div>
                            <h2>{config.expertReviews.heading}</h2>
                        </div>
                        <div className="col-md-12 wow fadeInUp">
                            <div id="testimonialCarousel" className="carousel" data-aos="fade-up" data-aos-duration="900">
                                <Slider {...settings}>
                                    {config.expertReviews.testimonials.map((testimonial, index) => (
                                        <div
                                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                                            key={index}
                                        >
                                            <div className="card">
                                                <div className="card-body">
                                                    <p className="card-text">{testimonial.quote}</p>
                                                    <div className="expertReviews_img">
                                                        <img
                                                            src={testimonial.image}
                                                            alt={testimonial.name}
                                                            title={testimonial.name}
                                                            width="60"
                                                            height="60"
                                                        />
                                                        <div>
                                                            <h3 className="card-title fw-bold">
                                                                {testimonial.name}
                                                            </h3>
                                                            <span className="text-secondary">{testimonial.title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* Home Blog Section */}
            <section className={config.homeBlog.sectionClass}>
                <div className="container-mid">
                    <div className="row">
                        <div className="col-md-12 main-heading mb-4 text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div className="sub-head">[Featured Articles]</div>
                            <h2>{config.homeBlog.heading}</h2>
                            <p>{config.homeBlog.description}</p>
                        </div>
                    </div>

                    <BlogList slugs={config.homeBlog.articles} />

                    <div className="row">
                        <div className="col-md-12 text-center wow fadeInUp">
                            <Link to="/blog" className="theme-btn mt-4">
                                <span>Read More Articles</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* Grow Site CTA Section */}
            <div className={config.growSiteCTA.sectionClass}>
                <div className="container-mid">
                    <div className="growSite_cta_content text-center">
                        <div className="row">
                            <div className="col-md-12 main-heading heading-light">
                                <span className="sub-head">Ready To Grow</span>
                                <h2>{config.growSiteCTA.heading}</h2>
                                <p className="mt-2 mb-3">{config.growSiteCTA.description}</p>
                                {config.growSiteCTA.ctaButtons.map((button, index) => (
                                    <a
                                        href={button.link}
                                        className={button.className}
                                        key={index}
                                    >
                                        <span>{button.text}</span>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
export default Home;
