import React, { useEffect, useState } from 'react';
import { getMenuBySlug, getSettings } from "../api/wordpress";
import logo from '../images/easy-agent.png';
import { Link } from 'react-router-dom';

export const headerConfig = {
    menuSlugs: {
        MAIN_MENU: 'main-menu',
    },
    ctaLink: "/signup",
    defaultLogo: logo,
};

function Header() {
    const [menuItems, setMenuItems] = useState({});
    const [logo, setLogo] = useState(headerConfig.defaultLogo);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [scrolled, setScrolled] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const data = await getSettings();
                if (data.site_logo) {
                    setLogo(data.site_logo);
                }
            } catch (err) {
                console.error(err.message);
            }
        };

        const fetchMenuItemsBySlug = async (slug) => {
            try {
                const data = await getMenuBySlug(slug);
                setMenuItems((prevState) => ({
                    ...prevState,
                    [slug]: data.items || [],
                }));
                setLoading(false);
            } catch (err) {
                console.error(`Error fetching menu by slug "${slug}": ${err.message}`);
                setLoading(false);
            }
        };

        // Handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 50) {  // Add .header-active when scrolled past 50px
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Attach scroll event listener
        window.addEventListener("scroll", handleScroll);

        fetchSettings();
        fetchMenuItemsBySlug(headerConfig.menuSlugs.MAIN_MENU);
    }, []);

    const renderSkeletonLoader = () => (
        <ul className="navbar-nav mx-auto">
            {Array(4).fill(0).map((_, index) => (
                <li className="nav-item skeleton-loader" key={index}></li>
            ))}
        </ul>
    );

    const renderMenuItems = (slug) => {
        const items = menuItems[slug] || [];
        return (
            <ul className="navbar-nav mx-auto">
                {items.map((item, index) => (
                    <li className="nav-item" key={index}>
                        <Link
                            className="nav-link"
                            to={item.url === "https://www.easyagentpro.com/leadsites/" ? "/leadsite" : item.url}
                            dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                            onClick={toggleNavbar}
                        ></Link>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>
            <header className={`site-header ${scrolled ? 'header-active' : ''}`} id="header">
                <div className="header-bottom">
                    <div className="container-mid">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand" to="/">
                                <img src={logo} className="img-fluid" alt="easyagentpro" title="easyagentpro" width="96" height="96" />
                            </Link>

                            <button
                                className={`navbar-toggler ${isOpen ? "" : "collapsed"}`}
                                type="button"
                                onClick={toggleNavbar}
                                aria-expanded={isOpen ? "true" : "false"}
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="websitenavbar">
                                {loading ? renderSkeletonLoader() : renderMenuItems(headerConfig.menuSlugs.MAIN_MENU)}
                                <div className="headerCta">
                                    <Link to={headerConfig.ctaLink}><span>Get Started</span></Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
