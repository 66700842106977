import React, { useEffect, lazy, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import AOS from 'aos';
import Skeleton from 'react-loading-skeleton';
import NotFound from './notFound';

// Lazy Loading for Other Components
const HeaderLeads = lazy(() => import('../components/apps/header-leads'));
const ContactMe = lazy(() => import('../components/apps/contact-me'));
const LeadLock = lazy(() => import('../components/apps/leadlock'));
const SmartMenu = lazy(() => import('../components/apps/smart-menu'));
const SmartPop = lazy(() => import('../components/apps/smart-pop'));
const SmartSlideIn = lazy(() => import('../components/apps/smart-slide-in'));
const Squeeze = lazy(() => import('../components/apps/squeeze'));
const LeadCrm = lazy(() => import('../components/apps/leads-crm'));
const Paths = lazy(() => import('../components/apps/paths'));
const BragWall = lazy(() => import('../components/apps/brag-wall'));
const SocialShare = lazy(() => import('../components/apps/social-share'));
const BloggerPro = lazy(() => import('../components/apps/blogger-pro'));
const EasyCma = lazy(() => import('../components/apps/easy-cma'));
const FindMe = lazy(() => import('../components/apps/find-me'));
const InstaFarm = lazy(() => import('../components/apps/instafarm'));
const Texty = lazy(() => import('../components/apps/texty'));

// Configuration Data
const AppConfig = {
   featuresList: {
           subHeading: '[Features]',
           heading: 'LeadSites - Features for Every Strategy',
           description: 'Learn more about these powerful tools.',
           links: {
               "Leads CRM": { "name": "Leads CRM", "url": "/explore/leads-crm" },
               "Easy CMA": { "name": "Easy CMA", "url": "/explore/easy-cma" },
               "Open House App": { "name": "Open House App", "url": "/explore/open-house-app" },
               "Facebook Lead Ads": { "name": "Facebook Lead Ads", "url": "/explore/facebook-lead-ads" },
               "Team": { "name": "Team", "url": "/explore/team" },
               "Find Me": { "name": "Find Me", "url": "/explore/find-me" },
               "Cookies": { "name": "Cookies", "url": "/explore/cookies" },
               "Texty": { "name": "Texty", "url": "/explore/texty" },
               "Pixels": { "name": "Pixels", "url": "/explore/pixels" },
               "Instafarm": { "name": "Instafarm", "url": "/explore/instafarm" },
               "LeadLock": { "name": "LeadLock", "url": "/explore/leadlock" },
               "Blogger Pro": { "name": "Blogger Pro", "url": "/explore/blogger-pro" },
               "Brag Wall": { "name": "Brag Wall", "url": "/explore/brag-wall" },
               "Header Leads": { "name": "Header Leads", "url": "/explore/header-leads" },
               "Social Share": { "name": "Social Share", "url": "/explore/social-share" },
               "Contact Me": { "name": "Contact Me", "url": "/explore/contact-me" },
               "Smart Menu": { "name": "Smart Menu", "url": "/explore/smart-menu" },
               "Paths": { "name": "Paths", "url": "/explore/paths" },
               "Smart Popup": { "name": "Smart Popup", "url": "/explore/smart-popup" },
               "Smart Slide-In": { "name": "Smart Slide-In", "url": "/explore/smart-slide-in" },
               "Squeeze": { "name": "Squeeze", "url": "/explore/squeeze" }
           }
       },
       growSiteCTA: {
           subHeading: '[LeadSite]',
           heading: 'I Want A LeadSite Now!',
           buttons: [
               { text: 'Get Started', link: 'https://www.easyagentpro.com/leadsites-appointment/' },
               { text: 'See A Demo', link: '/signup' }
           ]
       }
};
// Component Map
const componentsMap = {
    "contact-me": ContactMe,
    "header-leads": HeaderLeads,
    "leadlock": LeadLock,
    "smart-menu": SmartMenu,
    "smart-popup": SmartPop,
    "smart-slide-in": SmartSlideIn,
    "squeeze": Squeeze,
    "leads-crm": LeadCrm,
    "paths": Paths,
    "brag-wall": BragWall,
    "social-share": SocialShare,
    "blogger-pro": BloggerPro,
    "easy-cma": EasyCma,
    "find-me": FindMe,
    "instafarm": InstaFarm,
    "texty": Texty,
};

const FeatureAppDetail = () => {
    const { appSlug } = useParams();
    const DynamicComponent = componentsMap[appSlug];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [appSlug]);

    useEffect(() => {
        AOS.refresh();
    });

    if (!DynamicComponent) {
        return <div className="text-center"><NotFound/></div>;
    }

    return (
        <Suspense fallback={<div className="container text-center mt-4"><Skeleton height={400}  /></div>}>
            <DynamicComponent />
            {/* Features List */}
            <section className="leadSites_feature wow fadeInUp" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
                <div className="container-mid text-center">
                    <div className="main-heading">
                        <div className="sub-head">{AppConfig.featuresList.subHeading}</div>
                        <h2>{AppConfig.featuresList.heading}</h2>
                        <p>{AppConfig.featuresList.description}</p>
                    </div>
                    <ul>
                        {Object.entries(AppConfig.featuresList.links).map(([key, feature]) => (
                            <li key={key}>
                                <Link to={feature.url}>{feature.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            {/* Grow Site CTA */}
            <div className="growSite_cta text-center">
                <div className="container-mid">
                    <div className="growSite_cta_content">
                        <div className="main-heading heading-light" data-aos="fade-up" data-aos-duration="600" data-aos-delay="0">
                            <span className="sub-head">{AppConfig.growSiteCTA.subHeading}</span>
                            <h2>{AppConfig.growSiteCTA.heading}</h2>
                            {AppConfig.growSiteCTA.buttons.map((button, index) => (
                                <Link key={index} to={button.link} className="theme-btn white-btn ms-2 mt-3">
                                    <span>{button.text}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Suspense>
    );
};

export default FeatureAppDetail;
