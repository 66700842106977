
import React, { useEffect } from 'react';
import contactMe from '../images/app/logo-contactme.webp'
import headerLeads from '../images/app/logo-headerleads.webp'
import leadLock from '../images/app/logo-leadlock.webp'
import smartMenu from '../images/app/logo-smartmenu.webp'
import smartPopup from '../images/app/smart-popup-logo.webp'
import smartSlideIn from '../images/app/smart-slide-in-app-icon.webp'
import squeeze from '../images/app/logo-squeeze.webp'
import crm from '../images/app/crm-icon-new.webp'
import paths from '../images/app/paths-logo.webp'
import texty from '../images/app/logo-texty.webp'
import bragWall from '../images/app/logo-bragwall.webp'
import socialShare from '../images/app/logo-socialshare.webp'
import bloggerPro from '../images/app/logo-bloggerpro.webp'
import homeValuation from '../images/app/logo-homevaluatio.webp'
import findMe from '../images/app/logo-findme.webp'
import instafarm from '../images/app/logo-instafarm.webp'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import Seo from '../components/Seo';

// Configuration object for LeadSites feature sections
const leadSitesConfig = {
  metadetails: {
    title: 'LeadSites Lead Generation Features | Easy Agent PRO',
    description: 'LeadSites include online apps that help real estate agents capture leads and turn them into real estate buyers & sellers. See what features help make this happen here.',
    canonical: 'https://www.easyagentpro.com/explore/'
  },
  pageBanner: {
    backgroundImage: 'https://templatekits.themewarrior.com/leadgenz-new/wp-content/uploads/sites/101/2023/01/JS2EXNG-min.jpg',
    title: 'Features - Apps',

  },
  leadsDescSection: {
    subHeading: '[More Leads]',
    heading: 'What Makes LeadSites Different?',
    description: `We offer a full-service solution for Real Estate Agents. An IDX Website is just the start. We have a built-in CRM, done-for-you-blog content, and a whole lot more! See the details below, or schedule a demo to see them in action!`,
    additionalInfo: 'Click or tap any of the apps to see more detail. On desktop, hover to get a quick description.',
    ctaText: 'Get Started Now',
    ctaLink: '/eap-pricing'
  },
  leadsAppsSection: [
    {
      category: 'Lead Capture',
      description: 'These apps are designed to help you capture leads at various points on your website. Set up a form, a pop-up, or even a landing page.',
      apps: [
        { name: 'Contact Me', image: contactMe, description: 'Add a beautiful contact form to any page of your site.', link: '/explore/contact-me' },
        { name: 'Header Leads', image: headerLeads, description: 'A simple call-to-action at the top of your site easily drives traffic to your highest converting pages.', link: '/explore/header-leads' },
        { name: 'LeadLock', image: leadLock, description: 'Don\'t have time for a full funnel? Call this "lead capture lite" and don\'t let another pageview slip away.', link: '/explore/leadlock' },
        { name: 'Smart Menu', image: smartMenu, description: 'Stay top of mind AND top of page with this attractive site-wide page header that displays your info and branding.', link: '/explore/smart-menu' },
        { name: 'Smart Popup', image: smartPopup, description: 'Pop-ups are super effective, but done wrong, they can be super annoying. One app to rule them all!', link: '/explore/smart-popup' },
        { name: 'Smart Slide-In', image: smartSlideIn, description: 'A subtle lead-capture tool on every page of your site.', link: '/explore/smart-slide-in' },
        { name: 'Squeeze', image: squeeze, description: 'High converting landing pages that are easy to make, edit, and share!', link: '/explore/squeeze' }
      ]
    },
    {
      category: 'Lead Management',
      description: 'These apps help make sure you’re sending the right things as soon as possible as well as make sure your leads get into the right track in your CRM. We have a brand new CRM, or you can bring your own!',
      apps: [
        { name: 'Leads CRM', image: crm, description: 'An all-in-one client-management tool built right into your LeadSite!', link: '/explore/leads-crm' },
        { name: 'Paths', image: paths, description: 'Capturing leads is just the beginning – send their information automatically to your CRM complete with tags and kick off sequences automatically.', link: '/explore/paths' },
        { name: 'Texty', image: texty, description: 'Immediate text response wins the day – get things off on the right foot with every lead.', link: '/explore/texty' }
      ]
    },
    {
      category: 'Promotion',
      description: 'These apps help you build traffic and trust for your website. Create testimonials to share across your site and manage your social sharing options.',
      apps: [
        { name: 'Brag Wall', image: bragWall, description: 'Gather and control testimonials so visitors know you’re the best at what you do!', link: '/explore/brag-wall' },
        { name: 'Social Share', image: socialShare, description: 'Helping you help them share your original content, listings, and neighborhood pages.', link: '/explore/social-share' }
      ]
    },
    {
      category: 'SEO and Content',
      description: 'These apps help you get found by search engines like Google and Bing. Create neighborhood communities, optimize your entire site, and manage your real estate blog.',
      apps: [
        { name: 'Blogger Pro', image: bloggerPro, description: 'Set your blog up for success by choosing the right template, lead capture, and call-to-action.', link: '/explore/blogger-pro' },
        { name: 'Easy CMA', image: homeValuation, description: 'Your own home value pages are just a click away – create detailed analyses that generate leads!', link: '/explore/easy-cma' },
        { name: 'Find Me', image: findMe, description: 'We all want to be found online – this app sets you up for Google success!', link: '/explore/find-me' },
        { name: 'Instafarm', image: instafarm, description: 'Grow something in the digital dirt – neighborhood pages make it easy for visitors to see your deep local knowledge.', link: '/explore/instafarm' }
      ]
    }
  ],
  growSiteCTA: {
    subHeading: 'Ready To Grow',
    heading: 'Want to see them in action? Convinced?',
    ctaText1: 'Free Demo',
    ctaText2: 'See Pricing',
    ctaLink1: 'https://www.easyagentpro.com/leadsites-appointment/',
    ctaLink2: '/eap-pricing'
  }
};

// FeatureApp Component
const FeatureApp = () => {

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <>
      <Seo
        title={leadSitesConfig.metadetails.title}
        description={leadSitesConfig.metadetails.description}
        url={window.location.href}
      />
      {/* Page Banner */}
      <section className="page-banner-section" style={{ backgroundImage: `url(${leadSitesConfig.pageBanner.backgroundImage})` }}>
        <div className="container-mid">
          <div className="page-banner-content">
            <h1>{leadSitesConfig.pageBanner.title}</h1>
            <nav aria-label="breadcrumb">

            </nav>
          </div>
        </div>
      </section>

      {/* Leads Description Section */}
      <section className="leadsDesc_section">
        <div className="container-mid">
          <div className="row align-items-center">
            <div className="col-md-12 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
              <div className="main-heading text-center">
                <div className="sub-head">{leadSitesConfig.leadsDescSection.subHeading}</div>
                <h2>{leadSitesConfig.leadsDescSection.heading}</h2>
                <p>{leadSitesConfig.leadsDescSection.description}</p>
                <p>{leadSitesConfig.leadsDescSection.additionalInfo}</p>
                <Link to={leadSitesConfig.leadsDescSection.ctaLink} className="theme-btn mt-3"><span>{leadSitesConfig.leadsDescSection.ctaText}</span></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Leads Apps Section */}
      {leadSitesConfig.leadsAppsSection.map((section, index) => (
        <section key={index} className={`leadsApps_section ${index % 2 === 1 ? 'bg-darker' : ''}`}>
          <div className="container-mid">
            <div className={`main-heading text-center mb-5 wow fadeInUp ${index % 2 === 1 ? 'heading-light' : ''}`} data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
              <h2>{section.category}</h2>
              <p>{section.description}</p>
            </div>
            <div className="row justify-content-center" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
              {section.apps.map((app, appIndex) => (
                <div key={appIndex} className="col-md-6 col-lg-3 wow fadeInUp">
                  <div className="leadsApps_grid">
                    <Link to={app.link} className="leadsApps_grid_icon">
                      <img src={app.image} className="img-fluid" alt={app.name} title={app.name} width="100" height="100" />
                    </Link>
                    <div className="leadsApps_grid_content">
                      <h3><Link to={app.link}>{app.name}</Link></h3>
                      <p>{app.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ))}

      {/* Grow Site CTA Section */}
      <div className="growSite_cta pt-3 mt-5">
        <div className="container-mid">
          <div className="growSite_cta_content text-center">
            <div className="row">
              <div className="col-md-12 main-heading heading-light">
                <span className="sub-head">{leadSitesConfig.growSiteCTA.subHeading}</span>
                <h2>{leadSitesConfig.growSiteCTA.heading}</h2>
                <Link to={leadSitesConfig.growSiteCTA.ctaLink1} className="theme-btn white-btn mt-3"><span>{leadSitesConfig.growSiteCTA.ctaText1}</span></Link>
                <Link to={leadSitesConfig.growSiteCTA.ctaLink2} className="theme-btn white-btn ms-2 mt-3"><span>{leadSitesConfig.growSiteCTA.ctaText2}</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureApp;
