import { useEffect } from "react";

const ContactSection = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    const calendlyScript = document.createElement("script");
    calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js";
    calendlyScript.async = true;
    document.body.appendChild(calendlyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(calendlyScript);

      
    };
  }, []);


  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="contact-form-module">
          <iframe src="https://api.leadconnectorhq.com/widget/form/IxpUXa7uC4un3KkL3p9Z" style={{ width: "100%", height: "100%", border: "none", borderRadius: "3px" }} id="inline-IxpUXa7uC4un3KkL3p9Z" data-layout="{'id':'INLINE'}" data-trigger-type="alwaysShow" data-activation-type="alwaysActivated" data-deactivation-type="neverDeactivate" data-form-name="Contact us page form" title="Contact us page +9-form"></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
