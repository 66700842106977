const API_BASE_URL = 'https://www.easyagentpro.com/wp-json/wp/v2'; 

// Function to encode credentials
// const encodeCredentials = (username, applicationPassword) => {
//   return btoa(`${username}:${applicationPassword}`);
// };

// Fetch data from a specific endpoint with Basic Authentication
const fetchData = async (endpoint) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic c2x0ZWNoc29mdDowTFpWIFBsZEYgNFVhUSBhY2JXIGxtSFQgV0Nseg==" // Replace with your Base64-encoded credentials
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  const response = await fetch(`${API_BASE_URL}/${endpoint}`, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to fetch data from endpoint: ${endpoint}`);
  }

  return await response.json();
};

// Fetch posts with featured image
export const getPosts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/posts?_embed`);
    
    if (!response.ok) {
      throw new Error(`Error fetching posts: ${response.statusText}`);
    }

    const posts = await response.json();
    
    // Map through the posts and extract the featured image
    return posts.map(post => ({
      ...post,
      featuredImage: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || 'https://via.placeholder.com/767x600', // Fallback image if no featured image
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPostByPage = async (page, categoryId = '') => {
  try {
    const url = categoryId ? `${API_BASE_URL}/posts?per_page=10&page=${page}&categories=${categoryId}` : `${API_BASE_URL}/posts?per_page=10&page=${page}`;

    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(`Error fetching posts: ${res.statusText}`);
    }
    const posts = await res.json();
    return posts;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Fetch pages
export const getPages = async () => {
  return await fetchData('pages?per_page=100');
};

// Fetch menus
export const getMenus = async () => {
  return await fetchData('menus'); 
};

// Fetch categories
export const getCategories = async () => {
  return await fetchData('categories?per_page=100');
};

export const getCategoryIdBySlug = async (slug) => {
  try {
    const categories = await fetchData('categories?per_page=100'); // Fetch all categories

    const category = categories.find(cat => cat.slug === slug);
    if (!category) {
      throw new Error(`Category with slug "${slug}" not found.`);
    }

    return category.id; // Return the category ID
  } catch (error) {
    console.error(`Error fetching category ID: ${error.message}`);
    return null;
  }
};

// Fetch settings
export const getSettings = async () => {
  return await fetchData('settings');
};

// Fetch menu by slug
export const getMenuBySlug = async (slug) => {
  try {
    const menus = await fetchData(`menus?slug=${slug}`);
    if (!menus || menus.length === 0) {
      throw new Error(`Menu with slug "${slug}" not found.`);
    }
    const menu = menus[0]; // Get the first menu
    const menuId = menu.id; // Extract the menu ID

    // Fetch the menu items using the menu ID
    const menuItems = await fetchData(`menu-items?menus=${menuId}`);

    // Return the menu along with its items
    return { 
      id: menu.id,
      name: menu.name,
      slug: menu.slug,
      items: menuItems
    };
  } catch (error) {
    throw new Error(`Error fetching menu by slug: ${error.message}`);
  }
};

// Fetch posts by category slug
export const getPostsByCategorySlug = async (slug) => {
  try {
    const categories = await fetchData('categories');
    
    const category = categories.find((cat) => cat.slug === slug);
    if (!category) {
      throw new Error(`Category with slug "${slug}" not found.`);
    }

    // Fetch posts within the category
    const posts = await fetchData(`posts?categories=${category.id}`);
    return posts;
  } catch (err) {
    console.error(`Error fetching posts by category: ${err.message}`);
    throw err;
  }
};

// Fetch specific page by slug
export const getPageBySlug = async (slug) => {
  return await fetchData(`pages?slug=${slug}`);
};

const postData = async (endpoint, data) => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic c2x0ZWNoc29mdDowTFpWIFBsZEYgNFVhUSBhY2JXIGxtSFQgV0Nseg==" // Replace with your Base64-encoded credentials
  );
  myHeaders.append("Content-Type", "application/json");

  // const requestOptions = {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: JSON.stringify(data),
  //   redirect: "follow",
  // };
}
export const submitContactForm = async (formData) => {
  try {
    const contactFormData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    const result = await postData('contact-form', contactFormData); // Use your custom endpoint here
    console.log('Contact form submitted successfully:', result);
  } catch (error) {
    console.error('Error submitting contact form:', error);
  }
}

// Fetch blog details by slug
export const getBlogBySlug = async (slug) => {
  try {
    // Fetch posts filtered by the provided slug
    const blogs = await fetchData(`posts?slug=${slug}`);
    if (!blogs || blogs.length === 0) {
      throw new Error(`Blog with slug "${slug}" not found.`);
    }
    const blog = blogs[0]; // Get the first matching blog post

    return blog;


    // Extract the featured image if available
    // return {
    //   ...blog,
    //   featuredImage: blog._embedded?.['wp:featuredmedia']?.[0]?.source_url || 'https://via.placeholder.com/767x600', 
    // };
  } catch (error) {
    console.error(`Error fetching blog by slug: ${error.message}`);
    throw error;
  }
};

export const getBlogObjWithSlug = async (slug) => {
  try {
    const blogs = await fetchData(`posts?slug=${slug}`);
    if (!blogs || blogs.length === 0) {
      throw new Error(`Blog with slug "${slug}" not found.`);
    }
    const blog = blogs[0];
    return {
      title: blog.title.rendered || 'Untitled',
      image: blog.yoast_head_json.og_image[0].url || '',
      date: blog.date || 'Unknown date',
      excerpt: blog.excerpt?.rendered || 'No excerpt available.',
      link: `/blog/${slug}`,
    };
  } catch (error) {
    console.error(`Error fetching blog by slug: ${error.message}`);
    throw error;
  }
};
