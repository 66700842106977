import React, { useState, useEffect } from "react";
import { getCategories, getPostByPage } from "../api/wordpress";
import Skeleton from 'react-loading-skeleton';
import blogSidebar from "../images/blog/blog_sidebar_new.webp"
import blogDefault from "../images/blog/Blog_default.png"
import { Link, useParams } from "react-router-dom";
import Seo from "../components/Seo";
const config = {

	metadetails: {
		title: 'Real Estate Marketing Blog | Easy Agent PRO',
		description: 'Learn how you can manage real estate marketing smarter and get more qualified leads with the Easy Agent PRO’s blog for REALTORS®. Start reading here.',
		canonical: 'https://www.easyagentpro.com/blog/'
	},

	banner: {
		backgroundImage: "url('https://templatekits.themewarrior.com/leadgenz-new/wp-content/uploads/sites/101/2023/01/JS2EXNG-min.jpg')",
		title: "Welcome to the blog",

	},

	sidebar: {
		signupImage: blogSidebar,
		buttons: [
			{
				text: "Learn SEO",
				link: "/signup",
			},
			{
				text: "Learn Facebook",
				link: "/signup",
			},
		],
		signupForm: {
			title: "Get free tips 2x per week...",
			buttonText: "Sign Me Up",
		},
	},
};

const BlogList = () => {
	const { categorySlug } = useParams();
	const [blogs, setBlogs] = useState([]);
	const [page, setPage] = useState(1);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);
	const [error, setError] = useState(null);
	const [pageLoader, setPageLoader] = useState(true)

	useEffect(() => {

		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		setTimeout(() => {
			setPageLoader(false)
		}, 700);

		setLoading(true);

		const fetchCategories = async () => {
			try {
				const categories = await getCategories();
				const category = categories.find(cat => cat.slug === categorySlug);
				await fetchPosts(category ? category.id : '');
				setCategories(categories);
			} catch (err) {
				setError("Failed to fetch categories");
			}
		};

		const fetchPosts = async (category) => {

			const posts = await getPostByPage(page, category);

			if (posts.length === 0) {
				setHasMore(false); // No more pages left
				setPage((prev) => prev - 1); // Prevent going to an empty page
			} else {
				setBlogs(posts);
			}
			setLoading(false);
		};

		fetchCategories();

	}, [page,categorySlug]);


	const handlePageChange = (newPage) => {
		if (newPage < 1 || !hasMore) return; // Prevent invalid pages
		setPage(newPage);
	};

	const truncateHtml = (html, wordLimit) => {
		const doc = new DOMParser().parseFromString(html, "text/html");
		const textContent = doc.body.textContent || ""; // Extract text content (decodes entities)
		const words = textContent.split(/\s+/).slice(0, wordLimit).join(" "); // Limit to 100 words
		return words;
	};

	// if (loading) {
	//   return <p>Loading blog posts...</p>;
	// }

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<div>
			{/* Banner Section */}

			<Seo
                title={config.metadetails.title}
                description={config.metadetails.description}
				url={window.location.href}
            />
			<section
				className="page-banner-section"
				style={{
					backgroundImage: config.banner.backgroundImage,
				}}
			>
				<div className="container-mid">
					<div className="page-banner-content">
						<h1>{config.banner.title}</h1>
					</div>
				</div>
			</section>

			{/* Blog List Section */}
			<section className="indexPage-blogSection py-5">
				<div className="container-mid">
					<div className="row">
						{/* Blog Posts */}
						<div className="col-lg-8 col-md-7">
							{loading || pageLoader ? <Skeleton count={2} height={400} /> : blogs.map((blog) => (
								<div className="Blog_grid_index" key={blog.id}>
									<Link to={`/blog/${blog.slug}`} className="Blog_grid_index_img">
										<img
											src={blog.featuredImage === "https://via.placeholder.com/767x600" ? blogDefault : blog.yoast_head_json.og_image[0].url}
											className="img-fluid"
											alt={blog.title.rendered}
											width="767"
											height="600"
										/>
									</Link>
									<div className="Blog_grid_index_content">
										{/* Display Categories */}
										<ul className="Blog_grid_index_cat">
											{blog.categories &&
												blog.categories.map((categoryId) => {
													const category = categories.find(c => c.id === categoryId);													
													return (
														category && (
															<li key={categoryId}>
																<Link to={`/category/blog/${category.slug}`}>{category.name}</Link>
															</li>
														)
													);
												})}
										</ul>
										{/* Blog Title */}
										<h2>
											<Link to={`/blog/${blog.slug}`} dangerouslySetInnerHTML={{ __html: blog.title.rendered }}></Link>
										</h2>
										{/* Blog Meta */}
										<div className="blogMeta_box">
											{new Date(blog.date).toLocaleDateString()}
										</div>
										{/* Blog Excerpt */}
										<p dangerouslySetInnerHTML={{ __html: truncateHtml(blog.excerpt.rendered, 30) + "..." }} />
										{/* Read More Link */}
										<Link to={`/blog/${blog.slug}`} className="readMore">
											Read More »
										</Link>
									</div>
								</div>
							))}
						</div>

						{/* Sidebar */}
						<div className="col-lg-4 col-md-5 ps-lg-5">
							<div className="blogIndex_sidebar">
								{/* Sidebar Signup */}
								<div className="blogSidebar-box blogSidebar-signup-img">
									<Link to="#">
										<img
											src={config.sidebar.signupImage}
											className="img-fluid"
											alt="sign up img"
											title="sign up img"
											width="600"
											height="400"
										/>
									</Link>
									{config.sidebar.buttons.map((button, index) => (
										<Link className="theme-btn w-100 mt-3" to={button.link} key={index}>
											<span>{button.text}</span>
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-lg-8 col-md-7">
							{/* Pagination */}
							<nav style={{ justifyItems: "center" }} aria-label="Page navigation">
								<ul className="pagination">
									<li className={`page-item ${page === 1 ? "disabled" : ""}`}>
										<button className="page-link" onClick={() => handlePageChange(page - 1)}>
											Previous
										</button>
									</li>

									{/* Show current page and a few around it */}
									{page > 2 && (
										<li className="page-item">
											<button className="page-link" onClick={() => handlePageChange(page - 2)}>
												{page - 2}
											</button>
										</li>
									)}
									{page > 1 && (
										<li className="page-item">
											<button className="page-link" onClick={() => handlePageChange(page - 1)}>
												{page - 1}
											</button>
										</li>
									)}

									<li className="page-item active">
										<span className="page-link">{page}</span>
									</li>

									{hasMore && (
										<>
											<li className="page-item">
												<button className="page-link" onClick={() => handlePageChange(page + 1)}>
													{page + 1}
												</button>
											</li>
											<li className="page-item">
												<button className="page-link" onClick={() => handlePageChange(page + 2)}>
													{page + 2}
												</button>
											</li>
										</>
									)}

									<li className={`page-item ${!hasMore ? "disabled" : ""}`}>
										<button className="page-link" onClick={() => handlePageChange(page + 1)}>
											Next
										</button>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>

			</section>
		</div >
	);
};

export default BlogList;
