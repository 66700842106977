import React, { useEffect } from "react";
import AOS from "aos";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './components/about';
import NotFound from './pages/notFound';
import BlogDetail from './pages/blogDetail';
import Pricing from './pages/pricing';
import Home from './pages/home';
import BlogList from './pages/blogList';
import LeadSite from './pages/leadSite';
import FeatureApp from './pages/featuredApp';
import Layout from './layout/Layout';
import FeatureAppDeatil from "./pages/featureAppDeatil";
import Sitemap from "./pages/siteMap";
function App() {
  
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out-back",
      delay: 200,
      once: true,
      mirror: false,
    });
  }, []);

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Layout />, // Wrap all pages inside Layout
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/leadsite", element: <LeadSite /> },
        { path: "/features-apps", element: <FeatureApp /> },
        { path: "/explore/:appSlug", element: <FeatureAppDeatil /> },
        { path: "/blog", element: <BlogList /> },
        { path: "/blog/:blogSlug", element: <BlogDetail /> },
        { path: "/category/blog/:categorySlug", element: <BlogList /> },
        { path: "/eap-pricing", element: <Pricing /> },
        { path: "/signup", element: <Pricing /> },
        { path: "/sitemap", element: <Sitemap /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
