import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getPages, getCategories, getPostsByCategorySlug } from "../api/wordpress";
import Skeleton from 'react-loading-skeleton';
import "./sitemap.scss";


const Sitemap = () => {
    const [pages, setPages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryBlogs, setCategoryBlogs] = useState({});

    useEffect(() => {
        const fetchPages = async () => {
            window.scrollTo({
                top: window.innerHeight / 2,
                behavior: "smooth",
            });

            try {
                const fetchedPages = await getPages();
                const sortedPages = [...fetchedPages].sort((a, b) => a.title.rendered.localeCompare(b.title.rendered));
                setPages(sortedPages);
            } catch (error) {
                console.error("Error fetching pages:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCategories = async () => {
            try {
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories);

                // Fetch category blogs after getting categories
                for (const category of fetchedCategories) {
                    const posts = await getPostsByCategorySlug(category.slug);
                    setCategoryBlogs((prevBlogs) => ({
                        ...prevBlogs,
                        [category.slug]: posts,
                    }));
                }

            } catch (error) {
                console.error("Error fetching Categories:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
        fetchPages();
    }, []);


    return (
        <>
            {/* Page Banner */}
            <section className="page-banner-section">
                <div className="container-mid">
                    <div className="page-banner-content">
                        <h1>Website Sitemap</h1>
                    </div>
                </div>
            </section>
            <div className="container-mid mb-5">
                {loading ? <Skeleton count={3} height={200} /> :
                    <>
                        {/* Pages Section */}
                        <div className="sitemap-section mt-3">
                            <h2>Pages</h2>
                            <hr></hr>
                            {pages.length > 0 ? (
                                <ul className="ul-Style">
                                    {pages.map((page) => (
                                        <li key={page.id}>
                                            <Link to={page.link}>{page.title.rendered}</Link>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No pages available.</p>
                            )}
                        </div>

                        {/* Categories & Posts Section */}
                        <div className="sitemap-section mt-5">
                            <h2>Categories & Posts</h2>
                            <hr></hr>
                            {categories.length > 0 ? (
                                <ul className="ul-Style">
                                    {categories.map((category) => (
                                        <li key={category.id} className="sitemap-category">
                                            <h3>
                                                <Link to={category.link}>Category - {category.name}</Link>
                                            </h3>

                                            {/* Show category blogs if available */}
                                            {categoryBlogs[category.slug] && categoryBlogs[category.slug].length > 0 ? (
                                                <ul className="sitemap-sublist ms-5">
                                                    {categoryBlogs[category.slug].map((blog) => (
                                                        <li key={blog.id}>
                                                            <Link to={blog.link}>{blog.title.rendered}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p ></p>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No categories available.</p>
                            )}
                        </div>
                    </>}
            </div>
        </>
    );
};

export default Sitemap;