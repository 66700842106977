import React, { useEffect, useState } from 'react';
import animatedPhoneAds from "../images/pricing/animatedphone_ads_apps.webp"
import animatedPhoneAdsRochester from '../images/pricing/animatedphone_ads_rochester.webp'
import animatedPhoneAdsNinja from '../images/pricing/animatedphone_ads_ninja.webp'
import animatedPhoneAdsSocial from '../images/pricing/animatedphone_ads_social.webp'
import aboutOurWebsites from '../images/pricing/about-our-websites.webp'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Seo from '../components/Seo';


// const images = require.context('../images', true);
// const imageList = images.keys().reduce((map, imagePath) => {
//     const name = imagePath.replace(/^.*[\\/]/, '').replace(/\.[^/.]+$/, ''); // Extract filename without extension
//     map[name] = images(imagePath); // Set name as the key and full URL as the value
//     return map;
// }, {});


const config = {
    metadetails: {
        title: 'Real Estate Marketing EAP Pricing | Easy Agent PRO',
        description: 'The Ultimate Marketing Tool for Agents.',
        canonical: 'https://www.easyagentpro.com/eap-pricing/'
    }
}

const PricingComponent = () => {
    const [billingCycle, setBillingCycle] = useState('monthly');

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    useEffect(() => {
        AOS.refresh();
    });

    const plans = [
        {
            name: 'PRO',
            price: { monthly: 229, yearly: 2290 },
            features: [
                'EAP CRM',
                'EAP Gohighlevel',
                'Over 20 app tools',
                'Organic Build',
                '2 weekly blogs',
                '1/mo video transcription',
                '10 Community niche pages (we create landing pages and add IDX/listings to the page)',
            ],
            support: [
                'Weekly training',
                'Phone support',
                'Setup fee $199',
            ],
        },
        {
            name: 'ULTIMATE',
            price: { monthly: 279, yearly: 2790 },
            features: [
                'EAP CRM',
                'Over 20 app tools',
                'EAP Gohighlevel',
                'Premium templates',
                'Cash offer tool',
                '2 weekly blogs',
                '1/mo video transcription',
                '10 Community niche pages (we create landing pages and add IDX/listings to the page)',
                'Whiteglove setup',
                '5/mo video transcription',
                '5 DFY Community niche pages (We create keyword-researched content for SEO)',
            ],
            support: [
                'Weekly training',
                'Phone support',
                'Setup fee $199',
            ],
        },
        {
            name: 'ULTIMATE +',
            price: { monthly: 399, yearly: 3990 },
            features: [
                'EAP CRM',
                'Over 20 app tools',
                'EAP Gohighlevel',
                'Premium templates',
                'Cash offer tool',
                'DFY SINGLE-CHANNEL PPC ad monthly',
                '2 weekly blogs',
                '1/mo video transcription',
                '10 Community niche pages (we create landing pages and add IDX/listings to the page)',
                'Whiteglove setup',
                '5/mo video transcription',
                '5 DFY Community niche pages (We create keyword-researched content for SEO)',
            ],
            support: [
                'Weekly training',
                'Phone support',
                'Setup fee $199',
            ],
        },
        {
            name: 'PREMIUM',
            price: { monthly: 899, yearly: 8990 },
            features: [
                'EAP CRM',
                'Over 20 app tools',
                'EAP Gohighlevel',
                'Premium templates',
                'Cash offer tool',
                'DFY MULTI-CHANNEL PPC ads',
                'DFY SEO (Easy SEO)',
                'EAP Chatbot',
                '2 weekly blogs',
                '1/mo video transcription',
                '10 Community niche pages (we create landing pages and add IDX/listings to the page)',
                'Whiteglove setup',
                '10/mo video transcription',
                '5 DFY Community niche pages (We create keyword-researched content for SEO)',
                '5 DFY Landing pages',
            ],
            support: [
                'Weekly training',
                'Phone support',
                'Setup fee $199',
            ],
        },
    ];

    const tools = [
        {
            "mainDivClass": "row align-items-center",
            "contentDivClass": "col-md-6 ps-lg-5 wow fadeInUp",
            "title": "Powerful EAP app tools",
            "description": "What do you want your website to do? SEO? Social media? Pay-per-click ads? Lead Capture? The answer is Lead Generation which involves all previously mentioned plus a few more. EAP has that for you with our tools. SEO instafarm app, Social Share app for social media, Squeeze app for PPC ads and those are just a few of the powerful EAP tools that launches your website into LeadSite capabilities.",
            "image": animatedPhoneAds
        },
        {
            "mainDivClass": "row align-items-center mt-5",
            "contentDivClass": "col-md-6 pe-lg-5 order-lg-first wow fadeInUp",
            "title": "Fresh branding",
            "description": "Make your LeadSite create awareness for your brand. With EAP you have access to multiple templates that have customizable capabilities. Additionally, EAP has a suite of Lead Capture templates and tools that will encourage your site traffic to move into your CRM as a lead.",
            "image": animatedPhoneAdsRochester
        },
        {
            "mainDivClass": "row align-items-center mt-5",
            "contentDivClass": "col-md-6 ps-lg-5 wow fadeInUp",
            "title": "Ninja IQ",
            "description": "EAP has a unique proprietary scoring system that helps you determine the overall health of your LeadSite for generating site traffic and Lead conversions.",
            "image": animatedPhoneAdsNinja
        },
        {
            "mainDivClass": "row align-items-center mt-5",
            "title": "Content is king",
            "contentDivClass": "col-md-6 pe-lg-5 order-lg-first wow fadeInUp",
            "description": "Tap into the power of EAP’s 100k social media followers learning digital marketing tricks and sharing real world experiences.",
            "image": animatedPhoneAdsSocial,
            "links": [`<li> Visit EAP extensive library at the <a href="#">EAP Youtube channel</a> </li><li> Listen to some <a href="#">EAP podcasts</a> </li> <li> Join the <a href="#">EAP-sponsored Facebook page</a> “Beat Zillow” and learn/share experiences </li>`]
        }
    ]


    return (
        <>

            <Seo
                title={config.metadetails.title}
                description={config.metadetails.description}
                url={window.location.href}
            />
            <section class="page-banner-section" style={{
                backgroundImage:
                    "url('https://templatekits.themewarrior.com/leadgenz-new/wp-content/uploads/sites/101/2023/01/JS2EXNG-min.jpg')",
            }}>
                <div class="container-mid">
                    <div class="page-banner-content">
                        <h1>Pricing</h1>
                    </div>
                </div>
            </section>
            <section className="eapPricing" id='eap-pricing'>
                <div className="container-mid">
                    <div class="row mb-4">
                        <div class="col-md-12 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div class="main-heading text-center">
                                <div class="sub-head">
                                    [Plan]
                                </div>
                                <h2>
                                    Real Estate Agents
                                </h2>
                                <div class="form-check form-switch" >
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Monthly</label>
                                    <div class="formSwitch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            checked={billingCycle === 'yearly'}
                                            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly')}
                                        />
                                    </div>
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Annual</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0" >
                        {plans.map((plan, index) => (
                            <div className="col-md-6 col-lg-3 mb-3" key={index}>
                                {/* <div className={`eapPricing_grid ${index === 1 || index === 2 ? 'active' : ''}`}> */}
                                <div className="eapPricing_grid">
                                    <div className="eapPricing_grid_content">
                                        <h3>{plan.name}</h3>
                                        <div className="packPrice">
                                            <span className="main-price">${plan.price[billingCycle]}</span>
                                            <span className="price-time"> / {billingCycle}</span>
                                        </div>
                                        <a href="https://www.easyagentpro.com/leadsites-appointment/" className="theme-btn w-100 mt-3">
                                            Demo
                                        </a>
                                        <a href="https://www.easyagentpro.com/signup/" className="theme-btn white-btn w-100 mt-3">
                                            Buy It
                                        </a>
                                    </div>
                                    <div className="eapPricing_grid_feature">
                                        <div className="eapPricing_grid_feature_title">FEATURES</div>
                                        <ul className="ulStyle">
                                            {plan.features.map((feature, idx) => (
                                                <li key={idx}>{feature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="eapPricing_grid_feature">
                                        <div className="eapPricing_grid_feature_title">SUPPORT</div>
                                        <ul className="ulStyle">
                                            {plan.support.map((supportItem, idx) => (
                                                <li key={idx}>{supportItem}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section class="agentVideos" id='agentVideos'>
                <div class="container-mid">
                    <div class="row">
                        <div class="col-md-12 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div class="main-heading text-center heading-light mb-5">
                                <div class="sub-head">
                                    [About LeadSites]
                                </div>
                                <h2>
                                    See what agents are saying about LeadSites
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div class="agentVideos_gird">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/iYdM0R3oUuo?si=hQURq8gd0Xdg4JA7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="col-md-6 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div class="agentVideos_gird">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/N-_x_5zUQZI?si=dK0YNR1u9v5ktdRX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="col-md-12 text-center mt-4 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <h3>
                                See What Others Are Saying About EAP
                            </h3>
                            <a href="#agentVideos" class="theme-btn white-btn" >Customer Reviews</a>
                        </div>
                    </div>

                </div>
            </section>

            <section class="marketingTool">
                <div class="container-mid">
                    <div class="row">
                        <div class="col-md-12 mb-5 main-heading text-center">
                            <div class="sub-head">
                                [Tools]
                            </div>
                            <h2>The Ultimate Marketing Tool for Agents</h2>
                        </div>
                    </div>
                    {tools.map((tool, index) => (
                        <div class={tool.mainDivClass} key={index}>
                            <div class="col-md-6 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                                <div class="marketingToolImg text-center">
                                    <img src={tool.image} class="img-fluid" alt="Content is king" title="Content is king" width="175" height="400" />
                                </div>
                            </div>
                            <div class={tool.contentDivClass} data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                                <div class="marketingTool_content main-heading">
                                    <h2>
                                        {tool.title}
                                    </h2>
                                    <p>
                                        {tool.description}
                                    </p>
                                    {tool.links && (
                                        <ul class="ulStyle" dangerouslySetInnerHTML={{ __html: tool.links }}>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>


            <section class="twoCta">
                <div class="container-mid">
                    <div class="row">
                        <div class="col-md-6 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <Link to="#" class="twoCta_grid">
                                <div class="main-heading text-center heading-light mb-0">
                                    <h2>
                                        Say HI! | 15 Min.
                                    </h2>
                                    <span class="theme-btn white-btn">
                                        Start Now
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="col-md-6 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <Link to="#" class="twoCta_grid">
                                <div class="main-heading text-center heading-light mb-0">
                                    <h2>
                                        Lets Keep It Short | 30 min
                                    </h2>
                                    <span class="theme-btn white-btn">
                                        Start Now
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section class="socialMedia_review">
                <div class="container-mid">
                    <div class="row">
                        <div class="col-md-12 wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                            <div class="main-heading text-center heading-light mb-0">
                                <h2>
                                    What Other Agents Are Saying About Our Websites…
                                </h2>
                                <img src={aboutOurWebsites} class="img-fluid mt-4" alt="reviews" title="reviews" width="1200" height="500" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricingComponent;
