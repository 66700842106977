import React, { useEffect, useState } from 'react';
import leadSitesBanner from '../images/lead-sites/leads-banner.webp'
import moreLeadsImg from '../images/lead-sites/more-leads-img.webp'
import zoodealio from '../images/lead-sites/zoodealio.gif'
import contentCreator from '../images/lead-sites/content_creator.gif'
import leadGen from '../images/lead-sites/lead-gen.gif'
import supportImg from '../images/lead-sites/support-img.gif'
import integration from '../images/lead-sites/intrgration.webp'
import leadGenFocused from '../images/lead-sites/lead-gen-focused.webp'
import easyToUse from '../images/lead-sites/easy-to-use.webp'
import robustToolbox from '../images/lead-sites/robust-toolbox.webp'
import industryInsights from '../images/lead-sites/industry-insights.webp'
import playButton from '../images/lead-sites/play-button.webp'
import latestUpdateFrom1 from '../images/lead-sites/latest-update-from2.webp'
import latestUpdateFrom2 from '../images/lead-sites/latest-update-from2.webp'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import Seo from '../components/Seo';


// Configuration for the LeadSites sections
const leadSitesConfig = {
  metadetails: {
    title: "Real Estate Lead Generation Website | Easy Agent PRO",
    description: "LeadSites is Easy Agent PRO’s lead generation platform that offers marketing services for real estate agents. See how LeadSites can help you here.",
    canonical: "https://www.easyagentpro.com/leadsites",
  },
  pageBanner: {
    title: "LeadSites",
    breadcrumb: ["Home", "LeadSites"],
    backgroundImage: leadSitesBanner,
  },
  moreLeads: {
    imgSrc: moreLeadsImg,
    heading: "Get More Real Estate Leads With A Beautiful, High Converting Website",
    description:
      "Showcase your brand with powerful, IDX-ready websites designed to convert more visitors into leads. Leadsites are packed with content creation tools and lead generation strategies for buyers and sellers.",
    buttons: [
      { text: "Get Started Now", link: "/eap-pricing", type: "theme-btn" },
      { text: "Free Demo", link: "#", type: "theme-btn white-btn mt-3 ms-3" },
    ],
  },
  leadTabs: [
    {
      id: "zoodealio_tab",
      title: "Zoodealio",
      content: {
        heading:
          "Impress sellers with a built-in cash investor network (that pays you a commission!)",
        description:
          "Arrive at each listing appointment with compelling offers, elevate your market presence and set yourself apart from competitors.",
        list: [
          "Streamline seller lead generation with solutions tailored to diverse seller leads",
          "Display a menu of real-time cash offers alongside potential listing prices for the open market.",
          "Effortlessly reach out to your leads using built-in mailer tools",
        ],
        imgSrc: zoodealio,
      },
    },
    {
      id: "content_creator_tab",
      title: "Content Creator Tools",
      content: {
        heading:
          "The industry's premier and most user-friendly content creation tools (Rated 4.7/5 - TheClose.com)",
        description:
          "Grow your audience and elevate your brand using our arsenal of over 25 apps tailored to effortlessly incorporate stunning content pages onto your website.",
        list: [
          "Showcase your listings, testimonials, endorsed vendors and beyond",
          "Create stunning neighborhood pages with dozens of unique templates",
          "User-friendly blog tools to optimize your pages and reach a larger audience",
        ],
        imgSrc: contentCreator,
      },
    },
    {
      id: "lead_gen_tab",
      title: "Lead Gen & IDX",
      content: {
        heading:
          "Elevate your online presence with sleek designs and robust lead generation & IDX tools",
        description:
          "Select from hundreds of templates to craft visually captivating websites that resonate with your branding.",
        list: [
          "Craft PPC campaigns with IDX and generate market reports for buyers and sellers",
          "Easily connect your social media ads with our landing page builder, and use built-in lead magnets for your Youtube strategies",
          "Choose from hundreds of homepage designs, and change yours whenever you want with the push of a button",
        ],
        imgSrc: leadGen,
      },
    },
    {
      id: "support_tab",
      title: "Support",
      content: {
        heading:
          "Benefit from industry expertise and a dedicated support team by your side",
        description:
          "Leverage our experienced support team, who have built thousands of real estate websites across diverse markets. Reach out for assistance via phone calls, whether for integrations, content creation, technical advice, or enhancing lead generation efforts.",
        list: [
          "Live phone support & 24-hour turnaround on most email requests",
          "On-demand strategy calls with our industry experts",
          "Facebook group support with over 9000 active members",
        ],
        imgSrc: supportImg,
      },
    },
    {
      id: "integrations_tab",
      title: "Integrations",
      content: {
        heading: "Integrate all your marketing tools seamlessly",
        description:
          "Our websites integrate with the industry's most popular real estate tools and CRMs. Don’t see yours? Contact us to discuss having it added!",
        imgSrc: integration,
      },
    },
  ],
  latestFeature: {
    imgSrc: "https://lh3.googleusercontent.com/G2rgdH9OUkzMoYZlKL4uzzZErZnv6VhzlErngbYDb5gNwFkHfvTMsPqJRzrqZcVdwtLV9ldyB2SGRz7UCC0fQwXBJEdZAy21CCM=w603",
    heading: "Discover your ideal style",
    description:
      "Our Homepage App was recently updated to allow hundreds of design variations. Create, update, or refresh with NO coding skills required!",
  },
  builtForAgent: [
    {
      title: "Lead Gen Focused",
      iconSrc: leadGenFocused,
      description: "Customizable calls-to-action make it easy for your leads to reach out",
    },
    {
      title: "Easy to use",
      iconSrc: easyToUse,
      description: "Flexible designs and tools make branding & marketing simple",
    },
    {
      title: "Robust Toolbox",
      iconSrc: robustToolbox,
      description: "Over 25 apps for any marketing strategy, from PPC to content focused",
    },
    {
      title: "Industry Insights",
      iconSrc: industryInsights,
      description: "Track your SEO and site metrics to stay informed as the industry changes",
    },
  ],
  moreLeadsSection: {
    imgSrc: moreLeadsImg,
    heading: "Get More Real Estate Leads With A Beautiful, High Converting Website",
    description:
      "Showcase your brand with powerful, IDX-ready websites designed to convert more visitors into leads. Leadsites are packed with content creation tools and lead generation strategies for buyers and sellers.",
    buttons: [
      { text: "Get Started Now", link: "/eap-pricing", type: "theme-btn" },
      { text: "Free Demo", link: "#", type: "theme-btn white-btn mt-3 ms-3" },
    ],
  },
  propertyExpertSection: {
    sectionTitle: "Experts Trust LeadSites For Their Businesses",
    subTitle: "[Experts]",
    features: [
      {
        title: "Karin Carr",
        subdescription: "Real Estate Coach",
        description: "Easy Agent PRO is hands down the thing that propelled my real estate career to an entirely new level.",
        imgSrc: 'https://lh3.googleusercontent.com/7uf6AWUDH6z5w1vExZ6XRIg58maf7IUHh6-sdCaedL5ZFSRu2tVZGKZoVZbngxXtceglvtYzeQtHHXi1ICYqEVYJLgXecSUXZlY=s0',
        videoUrl: 'https://www.youtube.com/embed/yHY4CoG0LOc?si=k9mTE2WIJlHV8j-n'
      },
      {
        title: "Sam Sansalone",
        subdescription: "Real Estate Agent",
        description: "I've been an agent for 14 years, and I've really never had a website like what I have through Easy Agent PRO.",
        imgSrc: 'https://lh3.googleusercontent.com/8Kx-eccPCYWhCSjLgxHYrzKFfNER_FKrTY3xetU9CtAgPOH9K2g-hkEr3CN7ZO6xxJEdpIemgEdS_dlKfImqp1Bfutw9Ho-Tvuk=s0',
        videoUrl: 'https://www.youtube.com/embed/BSOXALtLMfk?si=w2bArZCrIlmAgpA1'
      },
      {
        title: "Dustin Brohm",
        subdescription: "Lead Gen Coach",
        description: "If it wasn't for the ease of use that my Easy Agent PRO site gave me, I never would have been able to make content marketing work.",
        imgSrc: 'https://lh3.googleusercontent.com/91x45ffyyh1FafmhXq3YL10xFG3Uj2z1O9HNLJq6ZR02g4RfGNCFQUYRmXmoBazTWgalH3geF-sAISj-L0euYtx_tvlVWoJ5gyg=w266',
        videoUrl: 'https://www.youtube.com/embed/-hWCGNdZX-4?si=9yAFMv9e1NeWDzoN'
      }
    ],
  },
  latestUpdateSection: {
    sectionTitle: "Take a look at the latest update from our Development Team: Saved search and property engagement widget for Follow Up Boss (Select MLS boards only)",
    subTitle: "[Latest Update]",
    imgSrc1: latestUpdateFrom1,
    imgSrc2: latestUpdateFrom2,
  },
  latestFeatureSection: {
    imgSrc: "https://lh3.googleusercontent.com/G2rgdH9OUkzMoYZlKL4uzzZErZnv6VhzlErngbYDb5gNwFkHfvTMsPqJRzrqZcVdwtLV9ldyB2SGRz7UCC0fQwXBJEdZAy21CCM=w603",
    heading: "Discover your ideal style",
    description:
      "Our Homepage App was recently updated to allow hundreds of design variations. Create, update, or refresh with NO coding skills required!",
  },
  builtForAgentSection: {
    title: "Real Estate Websites Built for Agents",
    features: [
      {
        title: "Lead Gen Focused",
        description: "Customizable calls-to-action make it easy for your leads to reach out",
        imgSrc: "../images/lead-sites/lead-gen-focused.webp",
      },
      {
        title: "Easy to use",
        description: "Flexible designs and tools make branding & marketing simple",
        imgSrc: "images/lead-sites/easy-to-use.webp",
      },
      {
        title: "Robust Toolbox",
        description: "Over 25 apps for any marketing strategy, from PPC to content focused",
        imgSrc: "images/lead-sites/robust-toolbox.webp",
      },
      {
        title: "Industry Insights",
        description: "Track your SEO and site metrics to stay informed as the industry changes",
        imgSrc: "images/lead-sites/industry-insights.webp",
      },
    ],
  },
};

const LeadSites = () => {
  const [selectedVideo, setSelectedVideo] = useState("");

  useEffect(() => {
    AOS.refresh();
  });
  return (
    <>
      <Seo
        title={leadSitesConfig.metadetails.title}
        description={leadSitesConfig.metadetails.description}
        url={window.location.href}
      />
      {/* Page Banner */}
      <section
        className="page-banner-section"
        style={{ backgroundImage: `url(${leadSitesConfig.pageBanner.backgroundImage})` }}
      >
        <div className="container-mid">
          <div className="page-banner-content">
            <h1>{leadSitesConfig.pageBanner.title}</h1>
            <nav aria-label="breadcrumb">
              {/* <h6 className='text-white mt-3'>{leadSitesConfig.pageBanner.breadcrumb[0]} / {leadSitesConfig.pageBanner.breadcrumb[1]}</h6> */}
            </nav>
          </div>
        </div>
      </section>

      {/* More Leads Section */}
      <section className="moreLeads_section">
        <div className="container-mid">
          <div className="row align-items-center">
            <div className="col-md-6 wow fadeInLeft" data-aos="fade-right" data-aos-duration="900" data-aos-delay="0">
              <div className="moreLeads_img">
                <img
                  src={leadSitesConfig.moreLeads.imgSrc}
                  className="img-fluid"
                  alt="More Leads"
                  title="More Leads"
                  width="767"
                  height="600"
                />
              </div>
            </div>
            <div className="col-md-6 main-heading wow fadeInRight ps-lg-5" data-aos="fade-left" data-aos-duration="900" data-aos-delay="0">
              <div className="sub-head">[More Leads]</div>
              <h2>{leadSitesConfig.moreLeads.heading}</h2>
              <p>{leadSitesConfig.moreLeads.description}</p>
              {leadSitesConfig.moreLeads.buttons.map((btn, index) => (
                <Link to={btn.link} className={btn.type} key={index}>
                  <span>{btn.text}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Lead Tabs */}
      <section className="leadTabs">
        <div className="container-mid">
          <ul className="nav nav-pills wow fadeInUp" role="tablist" data-aos="fade-up" data-aos-duration="600" data-aos-delay="0">
            {leadSitesConfig.leadTabs.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a className={index === 0 ? 'nav-link active' : 'nav-link'} data-bs-toggle="pill" href={`#${tab.id}`}>
                  {tab.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-content wow fadeInUp">
            {leadSitesConfig.leadTabs.map((tab, index) => (
              <div id={tab.id} className={`tab-pane ${index === 0 ? 'active' : ''}`} key={index}>
                <div className="row align-items-center">
                  <div className="col-md-6 pe-lg-5" data-aos="fade-right" data-aos-duration="900" data-aos-delay="0">
                    <div className="main-heading leadTabs_content">
                      <h2>{tab.content.heading}</h2>
                      <p>{tab.content.description}</p>
                      <ul className="ulStyle">
                        {tab.content.list &&
                          tab.content.list.map((item, index) => <li key={index}>{item}</li>)}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6" data-aos="fade-left" data-aos-duration="900" data-aos-delay="0">
                    <div className="leadTabs_content_img">
                      <img
                        src={tab.content.imgSrc}
                        className="img-fluid"
                        alt={tab.title}
                        title={tab.title}
                        width="767"
                        height="600"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Latest Feature */}
      <section className="latestFeature_design">
        <div className="container-mid">
          <div className="row align-items-center">
            <div className="col-md-6 wow fadeInLeft" data-aos="fade-right" data-aos-duration="900" data-aos-delay="0">
              <div className="latestFeature_design_img">
                <img
                  src={leadSitesConfig.latestFeature.imgSrc}
                  className="img-fluid"
                  alt="latest feature design"
                  title="latest feature design"
                  width="767"
                  height="600"
                />
              </div>
            </div>
            <div className="col-md-6 ps-lg-5 wow fadeInRight" data-aos="fade-left" data-aos-duration="900" data-aos-delay="0">
              <div className="latestFeature_design_content main-heading">
                <div className="sub-head">[latest featured design]</div>
                <h2>{leadSitesConfig.latestFeature.heading}</h2>
                <p>{leadSitesConfig.latestFeature.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Built For Agent */}
      <section className="builtFor_agent">
        <div className="container-mid">
          <div className="row">
            <div className="col-md-12 main-heading mb-5">
              <div className="section-title text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">
                <div className="sub-head">[Built for Agents]</div>
                <h2>Real Estate Websites Built for Agents</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="0">
            {leadSitesConfig.builtForAgent.map((item, index) => (
              <div className="col-md-6 col-lg-3 wow fadeInUp" key={index}>
                <div className="builtFor_agent_grid">
                  <div className="builtFor_agent_grid_title">
                    <div className="builtFor_agent_grid_icon">
                      <img
                        src={item.iconSrc}
                        className="img-fluid"
                        alt={item.title}
                        title={item.title}
                      />
                    </div>
                    <h3>{item.title}</h3>
                  </div>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* More Leads Section */}
      <section class="latestUpdate">
        <div class="container-mid">
          <div class="main-heading text-center mw-100 mb-5 heading-light wow fadeInUp">
            <div class="sub-head">{leadSitesConfig.latestUpdateSection.subTitle}</div>
            <h2>{leadSitesConfig.latestUpdateSection.sectionTitle}</h2>
          </div>
          <div class="row">
            <div class="col-md-6 wow fadeInLeft">
              <div class="latestUpdate_img">
                <img src={leadSitesConfig.latestUpdateSection.imgSrc1} class="img-fluid" alt="latest update" title="latest update" width="767" height="600" />
              </div>
            </div>
            <div class="col-md-6 wow fadeInRight">
              <div class="latestUpdate_img">
                <img src={leadSitesConfig.latestUpdateSection.imgSrc2} class="img-fluid" alt="latest update" title="latest update" width="767" height="600" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Property Expert Section */}
      <section className="propertyExpert">
        <div className="container-mid">
          <div className="row">
            <div className="col-md-12 main-heading mb-5">
              <div className="section-title text-center wow fadeInUp" data-aos="fade-up" data-aos-duration="900">
                <div className="sub-head">{leadSitesConfig.propertyExpertSection.subTitle}</div>
                <h2>{leadSitesConfig.propertyExpertSection.sectionTitle}</h2>
                <p>{leadSitesConfig.propertyExpertSection.description}</p>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-duration="1200">
            {leadSitesConfig.propertyExpertSection.features.map((item, index) => (
              <div className="col-md-6 col-lg-4 wow fadeInUp" key={index}>
                <div class="propertyExpert_grid">
                  <div class="propertyExpert_grid_img">
                    <img
                      src={item.imgSrc}
                      className="img-fluid"
                      alt={item.title}
                      title={item.title}
                    />
                  </div>
                  <div class="propertyExpert_grid_content">
                    <h3>{item.title}</h3>
                    <div class="propertyExpert_Role">
                      Lead Gen Coach
                    </div>
                    <button type="button" class="propertyExpert_btn" data-bs-toggle="modal" onClick={() => setSelectedVideo(item.videoUrl)} data-bs-target={`#expert`}>
                      <img src={playButton} class="img-fluid" alt="play-button" title="play-button" width="40" height="40" />
                    </button>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div class="modal fade expertVideo" id="expert">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              {selectedVideo && (
                <iframe
                  width="560"
                  height="315"
                  src={selectedVideo}
                  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)}
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default LeadSites;
