import { Link } from "react-router-dom";
import blogTwitter from "../images/twitter.svg";
import blogLinkedIn from "../images/linkedin.svg";
import blogFacebook from '../images/blog-facebook.svg'

const SocialShare = ({ blogUrl }) => {
    const text = "Check this out!";
    const openPopup = (url) => {
        window.open(url, "_blank", "width=600,height=400");
    };

    return (
        <ul>
            <li>
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        openPopup(`https://www.facebook.com/sharer/sharer.php?u=${blogUrl}&display=popup`);
                    }}
                >
                    <img src={blogFacebook} className="img-fluid" alt="Facebook" title="Share on Facebook" width="32" height="32" />
                </Link>
            </li>
            <li>
                {/* Twitter */}
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        openPopup(`https://twitter.com/intent/tweet?url=${blogUrl}&text=${text}`);
                    }}
                >
                    <img src={blogTwitter} className="img-fluid" alt="Twitter" title="Share on Twitter" width="32" height="32" />
                </Link>
            </li>
            <li>
                {/* LinkedIn */}
                <Link
                    to="#"
                    onClick={(e) => {
                        e.preventDefault();
                        openPopup(`https://www.linkedin.com/sharing/share-offsite/?url=${blogUrl}`);
                    }}
                >
                    <img src={blogLinkedIn} className="img-fluid" alt="LinkedIn" title="Share on LinkedIn" width="32" height="32" />
                </Link>
            </li>
        </ul>
    );
};

export default SocialShare;
