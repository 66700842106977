import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getBlogObjWithSlug } from '../api/wordpress';

const BlogList = ({ slugs }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Fetch data for each blog slug and store it in the blogs array
        const blogData = await Promise.all(slugs.map((slug) => getBlogObjWithSlug(slug)));
        // Initialize showFullExcerpt state for each blog post
        const blogsWithFullExcerptState = blogData.map((blog) => ({
          ...blog,
          showFullExcerpt: false, // Set initial state to false
        }));

        setBlogs(blogsWithFullExcerptState);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [slugs]);


  return (
    <div className="row">
      {blogs.map((article, index) => (
        <div className="col-md-6 col-lg-4 wow fadeInUp" key={index}>
          <div className="Blog_grid" data-aos="fade-up" data-aos-duration="1300">
            <Link to={article.link} className="Blog_grid_img">
              <img
                src={article.image}
                className="img-fluid"
                alt={article.title}
                title={article.title}
                width="767"
                height="600"
              />
            </Link>
            <div className="Blog_grid_content">
              <h3>
                <Link to={article.link}>{article.title}</Link>
              </h3>
              <div className="blogMeta_box">{article.date}</div>
              <p>
                {article.excerpt && article.excerpt.length > 90
                  ? article.showFullExcerpt
                    ? <span dangerouslySetInnerHTML={{ __html: article.excerpt }} />
                    : <span dangerouslySetInnerHTML={{ __html: `${article.excerpt.substring(0, 90)}...` }} />
                  : <span dangerouslySetInnerHTML={{ __html: article.excerpt }} />}
              </p>
              
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
