import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import React from "react";
import { getBlogBySlug } from '../api/wordpress';
import SocialShare from '../components/SocialShareIcons';
import Seo from '../components/Seo';
const config = {
    banner: {
        backgroundImage: "url('https://templatekits.themewarrior.com/leadgenz-new/wp-content/uploads/sites/101/2023/01/JS2EXNG-min.jpg')",

    },
};
const BlogDetail = () => {
    const { blogSlug } = useParams();
    const [blogDetailData, setBlogDetailData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        // Fetch blog posts and categories on component mount
        const fetchBlogDetail = async (slug) => {
            try {
                const posts = await getBlogBySlug(slug)
                setBlogDetailData(posts);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch posts");
                setLoading(false);
            }
        };

        fetchBlogDetail(blogSlug);
    }, [blogSlug]);


    // if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!blogDetailData) return <div>No blog detail found.</div>;

    return (

        <>
            <Seo
                title={blogDetailData.yoast_head_json?.og_title}
                description={blogDetailData.yoast_head_json?.og_description}
                url={window.location.href}
                image={loading ? '' : blogDetailData && (blogDetailData.yoast_head_json.og_image[0].url)}

            />
            <section
                className="page-banner-section"
                style={{
                    backgroundImage: config.banner.backgroundImage,
                }}
            >
                <div className="container-mid">
                    <div className="page-banner-content">
                    </div>
                </div>
            </section>

            <section class="singlePage-blogSection pt-5">
                <div class="container-mid">
                    {loading ? <Skeleton count={3} height={200} /> : blogDetailData && (
                        <div class="row">
                            <div class="col-lg-8 col-md-7">
                                <div class="singlePage_container">
                                    <div class="singlePage_main_img">
                                        <img src={blogDetailData.yoast_head_json.og_image[0].url} class="img-fluid" width="1000" height="500" alt={blogDetailData.title.rendered} Title={blogDetailData.title.rendered} />
                                    </div>
                                    <div class="singlePage_main_content">
                                        <div class="singlePage_main_content_title">

                                            <h1 dangerouslySetInnerHTML={{ __html: blogDetailData.title.rendered }}>

                                            </h1>
                                            <div class="singlePage_date_social">
                                                <div class="singlePage_main_content_date">{new Date(blogDetailData.date).toLocaleDateString()}</div>
                                                <SocialShare blogUrl={window.location.href} />
                                            </div>
                                        </div>
                                        <div className='singlePage_main_content_container'>
                                            <div dangerouslySetInnerHTML={{ __html: blogDetailData.content.rendered }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5  ps-lg-5">
                                <div class="blogsingle_sidebar">
                                    <div class="blogSidebar-box blogSidebar-signup-img">
                                        <Link to="/leadsite">
                                            <img src="https://www.easyagentpro.com/wp-content/uploads/2024/11/Blog_sidebar_New.webp" class="img-fluid" alt="sign up img" title="sign up img" width="600"
                                                height="400" />
                                        </Link>
                                    </div>
                                    {/* <div class="blogSidebar-box blogSidebar-signup-form">
                                        <div class="sidebar_title"> Get free tips 2x per week...</div>
                                        <form>

                                            <div class="mb-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your Best Email" />
                                            </div>
                                            <button type="submit" class="theme-btn">Sign Me Up</button>
                                        </form>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default BlogDetail;
