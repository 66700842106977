// src/components/NotFound.js
import React from 'react';
import bgImage from '../images/not-found.png'
import { Link } from 'react-router-dom';

const Appconfig = {

  pageBanner: {
    backgroundImage: bgImage,
    title: 'Not Found',
  },

};

const NotFound = () => {
  return (
    <>
      {/* Page Banner */}
      <section className="page-banner-section">
        <div className="container-mid">
          <div className="page-banner-content">
            <h1>{Appconfig.pageBanner.title}</h1>
          </div>
        </div>
      </section>
      {/* 404 Content */}
      <div className='container-mid text-center my-5'>
        <img
          src={Appconfig.pageBanner.backgroundImage}
          className="img-fluid"
          alt='404'
          title='404'
          width="600"
          height="400"
        />
        <h2 className="font-extrabold text-gray-800">Sorry, the page you're looking for does not exist</h2>
        <Link to="/" className="theme-btn mt-5">
        <span>Go Back Home</span>
      </Link>
      </div>
    
    </>
  );
};

export default NotFound;
